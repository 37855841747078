<template>
  <div>
    <info-team
        v-if="isCanDo('updateTeamInfo')"
        :team-id="defaultTeamId"
    />
    <members
        v-if="isCanDo('indexTeamMembers')"
        :team-id="defaultTeamId"
    />
    <div v-if="isCanDo('sendInvitationToNewMember')">
      <send-invitation
          :team-id="defaultTeamId"
          @refresh="$refs.pendingInvitations.getData()"
      />
      <pending-invitations ref="pendingInvitations" />
    </div>
    <actions :team-id="defaultTeamId" />
  </div>
</template>
<script>

import infoTeam from "@/components/settings/teams/info.vue"
import Members from "@/components/settings/teams/members.vue"
import sendInvitation from "@/components/settings/teams/sendInvitation.vue"
import Actions from "@/components/settings/teams/actions.vue"
import PendingInvitations from "@/components/settings/teams/pending-invitations.vue"

export default {
  components: {
    infoTeam,
    Members,
    sendInvitation,
    Actions,
    PendingInvitations
  },
  data() {
    return {
      defaultTeamId: this.getCurrentTeam().id,
    }
  },
}
</script>
