<template>
  <b-card class="mb-3 p-2 pt-2 pb-2">
    <b-card-title class="mb-1">
      {{ title }}
    </b-card-title>
    <b-card-text class="mb-2">
      {{ description }}
    </b-card-text>
    <slot/>
  </b-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    }
  }
}
</script>
