<template>
  <div>
    <loading :show="isLoading">
      <settings-card
          :description="$i18n.t('user.settings.teams.cards.info.desc')"
          :title="$i18n.t('user.settings.teams.cards.info.title')"
      >
        <div class="w-50">
          <validation-observer
              ref="refFormObserver"
          >
            <!-- Name -->
            <b-form-group
                :label="$i18n.t('Name')"
                label-for="name"
            >
              <validation-provider
                  #default="{ errors }"
                  name="name"
                  rules="required"
              >
                <b-form-input
                    id="name"
                    v-model="dataForm.name"
                    :state="errors.length > 0 ? false:null"
                    name="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <colors :selected-color.sync="dataForm.color" />

            <!-- Update -->
            <b-button
                class="mr-2"
                variant="primary"
                @click="updateTeam()"
            >
              {{ $i18n.t('Update') }}
            </b-button>
          </validation-observer>
        </div>
      </settings-card>
    </loading>
  </div>
</template>
<script>

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {required} from '@validations'
import SettingsCard from '@/components/settings/card.vue'
import Colors from '@/components/common/colors.vue'
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  components: {
    SettingsCard,
    Colors,
  },
  props: {
    teamId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      required,
      isLoading: false,
      dataForm: {
        name: '',
        color: '',
      },
      repository: RepositoryFactory.create('teamsUser')
    }
  },
  computed: {
    canUpdateInfo() {
      return this.isCanDo('updateTeamInfo')
    }
  },
  mounted() {
    if (this.canUpdateInfo) this.getInfo()
  },
  methods: {
    getInfo() {
      this.isLoading = true
      this.repository.get(this.teamId).then(response => {
        const res = response.data.data
        this.dataForm.name = res.name
        this.dataForm.color = res.color
        this.isLoading = false
      })
    },
    updateTeam() {
      this.isLoading = true
      this.repository.update(this.teamId, this.dataForm).then(response => {
        const user = response.data.data
        this.$store.dispatch('auth/updateAuth', {user})
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: this.$i18n.t('Success'),
            icon: 'EditIcon',
            variant: 'success',
            text: response.data.message
          },
        })
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
      })
    },
    changeColor(color) {
      this.dataForm.color = color
    }
  }
}
</script>
