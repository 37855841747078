<template>
  <div>
    <form-modal
        id="formModal"
        ref="formModal"
        :show-modal.sync="showModal"
        @refetch-data="getData"
    />
    <loading :show="isLoading">
      <settings-card
          :description="$i18n.t('user.settings.teams.cards.members.desc')"
          :title="$i18n.t('user.settings.teams.cards.members.title')"
      >
        <b-table
            :empty-text="$i18n.t('there_are_no_data')"
            :fields="fields"
            :items="members"
            hover
            responsive="sm"
            show-empty
        >
          <template #cell(role)="data">
            {{ data.item.role.name }}
          </template>
          <!-- Column: Actions -->
          <template
              #cell(actions)="data"
          >
            <b-dropdown
                v-if="updateTeamMember(data.item) || deleteTeamMember(data.item)"
                no-caret
                variant="link"
            >
              <template #button-content>
                <feather-icon
                    class="align-middle text-body"
                    icon="MoreVerticalIcon"
                    size="16"
                />
              </template>
              <b-dropdown-item
                  v-if="updateTeamMember(data.item)"
                  @click="activeUpdateForm(data.item)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">{{ $i18n.t('Edit') }}</span>
              </b-dropdown-item>

              <b-dropdown-item
                  v-if="deleteTeamMember(data.item)"
                  @click="confirmDelete(data.item)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">{{ $i18n.t('Delete') }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </settings-card>
    </loading>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SettingsCard from '@/components/settings/card.vue'
import RepositoryFactory from "@/repositories/RepositoryFactory";
import FormModal from "@/components/settings/teams/form.vue"

export default {
  components: {
    SettingsCard,
    FormModal,
  },
  props: {
    teamId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      isModalActive: false,
      members: [],
      isLoading: false,
      repository: RepositoryFactory.create('teamMembers'),
      showModal: false,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'name',
          label: this.$i18n.t(`user.settings.teams.cards.members.fields.name`),
        },
        {
          key: 'email',
          label: this.$i18n.t(`user.settings.teams.cards.members.fields.email`),
        },
        {
          key: 'role',
          label: this.$i18n.t(`user.settings.teams.cards.members.fields.role`),
        },
        {
          key: 'actions',
          label: this.$i18n.t('Actions')
        }
      ]
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    updateTeamMember(member) {
      return this.isCanDo('updateTeamMember') && !member.role.is_default
    },
    deleteTeamMember(member) {
      return this.isCanDo('deleteTeamMember') && !member.role.is_default
    },
    activeUpdateForm(item) {
      this.showModal = true
      this.$refs.formModal.putUser(item)
    },
    getData() {
      this.isLoading = true
      this.repository.getAll(this.teamId).then(response => {
        this.members = response.data.data
        this.isLoading = false
      })
    },
    confirmDelete(item) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.is_loading = true
          this.repository.delete(item.id).then(response => {
            this.getData();
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$i18n.t('Success'),
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.message
              },
            })
          })
        }
      })
    },
  },
}
</script>
