<template>
  <div>
    <loading :show="isLoading">

      <settings-card
          :description="$i18n.t('user.settings.teams.cards.send_invitation.desc')"
          :title="$i18n.t('user.settings.teams.cards.send_invitation.title')"
      >
        <b-row>
          <b-col cols="8">
            <validation-observer
                ref="refFormObserver"
            >
              <b-form @submit.prevent="sendInvitation()">
                <b-row>
                  <!-- User Email -->
                  <b-col cols="12">
                    <validation-provider
                        #default="{ errors }"
                        name="email"
                        rules="required|email"
                    >
                      <b-form-group
                          :label="$i18n.t('Email')"
                          label-for="email"
                      >
                        <b-form-input
                            id="email"
                            v-model="form.email"
                            :state="errors.length > 0 ? false:null"
                            trim
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- User Language -->
                  <b-col cols="12">
                    <validation-provider
                        #default="{ errors }"
                        name="lang"
                        rules="required"
                    >
                      <b-form-group
                          :label="$i18n.t('Lang')"
                          label-for="user-lang"
                      >
                        <v-select
                            id="lang"
                            v-model="form.lang"
                            :clearable="false"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="available_locales"
                            :reduce="val => val"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <!-- User Role -->
                  <b-col cols="12">
                    <validation-provider
                        #default="{ errors }"
                        name="role"
                        rules="required"
                    >
                      <b-form-group
                          :label="$i18n.t('Role')"
                          label-for="user-role"
                      >
                        <v-select
                            id="role"
                            v-model="form.role"
                            :clearable="false"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="rolesOptions"
                            :reduce="val => val.id"
                            label="name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col cols="12">
                    <b-button
                        class="w-100 mt-2"
                        type="submit"
                        variant="primary"
                    >
                      {{ $i18n.t('user.settings.teams.cards.send_invitation.send_invitation_button') }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </settings-card>
    </loading>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SettingsCard from '@/components/settings/card.vue'
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  components: {
    SettingsCard,
  },
  props: {
    teamId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      isLoading: false,
      available_locales: [],
      rolesOptions: [],
      form: {
        email: "",
        lang: "en",
        role: "",
      },
      teamRepo: RepositoryFactory.create('teamsUser')
    }
  },
  mounted() {
    this.getUtils()
  },
  methods: {
    async getUtils() {
      this.isLoading = true
      await this.utilsAppRepository().getAvailableLocales().then(res => {
        this.available_locales = res.data.data
      })
      await this.utilsAppRepository().getRoles().then(res => {
        this.rolesOptions = res.data.data
      })
      this.isLoading = false
    },

    sendInvitation() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.isLoading = true
          this.teamRepo.sendInvitation(this.teamId, this.form).then(response => {
            this.isLoading = false
            this.$emit('refresh')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('Success'),
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.message
              },
            })
          }).catch(error => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('Error'),
                icon: 'TrashIcon',
                variant: 'danger',
                text: error,
              },
            })
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
