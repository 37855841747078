var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',_vm._b({attrs:{"ok-title":_vm.$i18n.t('Update'),"visible":_vm.showModal,"no-close-on-backdrop":""},on:{"cancel":_vm.close,"close":_vm.close,"ok":_vm.submitForm},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h5',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.$i18n.t('user.settings.teams.cards.members.modal-form.title'))+" ")])]},proxy:true}])},'b-modal',_vm.$attrs,false),[_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submitForm)},"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('validation-provider',{attrs:{"name":"userName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Name'),"label-for":"name"}},[_c('b-form-input',{attrs:{"id":"userName","state":errors.length > 0 ? false:null,"autofocus":"","disabled":"","placeholder":_vm.$i18n.t('Name'),"trim":""},model:{value:(_vm.userData.name),callback:function ($$v) {_vm.$set(_vm.userData, "name", $$v)},expression:"userData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"userEmail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Email'),"label-for":"email"}},[_c('b-form-input',{attrs:{"id":"userEmail","state":errors.length > 0 ? false:null,"disabled":"","trim":""},model:{value:(_vm.userData.email),callback:function ($$v) {_vm.$set(_vm.userData, "email", $$v)},expression:"userData.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"userRole","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$i18n.t('Role'),"label-for":"user-role"}},[_c('v-select',{attrs:{"clearable":false,"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.roles,"reduce":function (val) { return val.id; },"input-id":"user-role","label":"name"},model:{value:(_vm.userData.role),callback:function ($$v) {_vm.$set(_vm.userData, "role", $$v)},expression:"userData.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }