<template>
  <b-modal
      v-bind="$attrs"
      :ok-title=" $i18n.t('Update') "
      :visible="showModal"
      no-close-on-backdrop
      @cancel="close"
      @close="close"
      @ok="submitForm"
  >
    <template #modal-title>
      <h5 class="mb-0">
        {{ $i18n.t('user.settings.teams.cards.members.modal-form.title') }}
      </h5>
    </template>
    <!-- BODY -->
    <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit }"
    >
      <!-- Form -->
      <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
      >

        <!-- Name -->
        <validation-provider
            #default="{errors}"
            name="userName"
            rules="required"
        >
          <b-form-group
              :label="$i18n.t('Name')"
              label-for="name"
          >
            <b-form-input
                id="userName"
                v-model="userData.name"
                :state="errors.length > 0 ? false:null"
                autofocus
                disabled
                :placeholder="$i18n.t('Name')"
                trim
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- Email -->
        <validation-provider
            #default="{ errors }"
            name="userEmail"
            rules="required|email"
        >
          <b-form-group
              :label="$i18n.t('Email')"
              label-for="email"
          >
            <b-form-input
                id="userEmail"
                v-model="userData.email"
                :state="errors.length > 0 ? false:null"
                disabled
                trim
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>

        <!-- User Role -->
        <validation-provider
            #default="{ errors }"
            name="userRole"
            rules="required"
        >
          <b-form-group
              :label="$i18n.t('Role')"
              label-for="user-role"
          >
            <v-select
                v-model="userData.role"
                :clearable="false"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roles"
                :reduce="val => val.id"
                input-id="user-role"
                label="name"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>

import {email, required} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";

export default {
  emits: ['refetch-data'],
  props: {
    oldUserData: {
      type: Object,
      required: false,
      default: null,
    },
    showModal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      required,
      email,
      roles: [],
      userData: {},
      blankUserData: {
        id: '',
        name: '',
        email: '',
        role: '',
      },
      repository: RepositoryFactory.create('teamMembers'),
    }
  },
  computed: {},
  async mounted() {
    this.resetForm()
    await this.getUtils()
  },
  methods: {
    putUser(item) {
      this.userData = JSON.parse(JSON.stringify(item))
      this.userData.role = this.userData.role.id
    },
    async getUtils() {
      await this.utilsAppRepository().getRoles().then(res => {
        this.roles = res.data.data
      })
    },
    resetForm() {
      this.userData = JSON.parse(JSON.stringify(this.blankUserData))
    },
    close() {
      this.resetForm()
      this.$emit('update:show-modal', false)
    },
    submitForm() {
      const data = {
        name: this.userData.name,
        email: this.userData.email,
        role: this.userData.role,
      }
      this.repository.update(this.userData.id, data).then(response => {
        this.successCallback(response)
      }).catch(error => {
        this.errorCallback(error)
      })
    },
    successCallback(response) {
      this.$emit('refetch-data')
      this.resetForm()
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t('Success'),
          icon: 'EditIcon',
          variant: 'success',
          text: response.data.message
        },
      })
      this.$emit('update:show-modal', false)
    },
    errorCallback(error) {
      if (error.data && error.data.errors) {
        this.$refs.refFormObserver.setErrors(error.data.errors)
      }
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
