<template>
  <div v-if="invitations.length > 0">
    <loading :show="isLoading">
      <settings-card
          :description="$i18n.t('user.settings.teams.cards.pending-invitations.desc')"
          :title="$i18n.t('user.settings.teams.cards.pending-invitations.title')"
      >
        <b-table
            :empty-text="$i18n.t('there_are_no_data')"
            :fields="fields"
            :items="invitations"
            hover
            responsive="sm"
            show-empty
        >
          <!-- Column: Actions -->
          <template
              #cell(actions)="data"
          >
            <feather-icon
                v-if="isCanDo('deleteInvitation')"
                icon="TrashIcon"
                @click="confirmDelete(data.item)"
            />
          </template>
        </b-table>
      </settings-card>
    </loading>
  </div>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import SettingsCard from '@/components/settings/card.vue'
import RepositoryFactory from "@/repositories/RepositoryFactory"

export default {
  components: {
    SettingsCard,
  },
  data() {
    return {
      invitations: [],
      isLoading: false,
      repository: RepositoryFactory.create('invitations'),
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'email',
          label: this.$i18n.t(`Email`),
        },
        {
          key: 'role',
          label: this.$i18n.t(`Role`),
        },
        {
          key: 'actions',
          label: this.$i18n.t('Actions')
        }
      ]
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.isLoading = true
      this.repository.getAll().then(response => {
        this.invitations = response.data.data
        this.isLoading = false
      })
    },
    confirmDelete(item) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.is_loading = true
          this.repository.delete(item.id).then(response => {
            this.getData();
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$i18n.t('Success'),
                icon: 'EditIcon',
                variant: 'success',
                text: response.data.message
              },
            })
          })
        }
      })
    },
  },
}
</script>
