<template>
  <settings-card
      :description="$i18n.t('user.settings.teams.cards.actions.desc')"
      :title="getCurrentTeam().is_owner ?
       $i18n.t('user.settings.teams.cards.actions.title.delete') :
       $i18n.t('user.settings.teams.cards.actions.title.leaving')"
  >
    <b-button
        v-if="getCurrentTeam().is_owner"
        class="w-25 mr-2"
        variant="danger"
        @click="confirm('delete')"
    >
      {{ $i18n.t('delete-team') }}
    </b-button>

    <b-button
        v-else
        class="w-25 mr-2"
        variant="danger"
        @click="confirm('leave')"
    >
      {{ $i18n.t('leaving-team') }}
    </b-button>
  </settings-card>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import RepositoryFactory from "@/repositories/RepositoryFactory";
import SettingsCard from '@/components/settings/card.vue'

export default {
  components: {
    SettingsCard,
  },
  props: {
    teamId: {
      type: Number,
      required: true,
    }
  },
  data() {
    return {
      isLoading: false,
      repository: RepositoryFactory.create('teamsUser')
    }
  },
  methods: {
    confirm(action) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this!"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t(`Yes, ${action} it`),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          if (action === "delete") {
            this.deleteTeam()
          } else {
            this.leavingTeam()
          }
        }
      })
    },
    deleteTeam() {
      this.isLoading = true
      this.repository.delete(this.teamId).then(response => {
        this.successCallBack(response)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
      })
    },
    leavingTeam() {
      this.isLoading = true
      this.repository.leaving(this.teamId).then(response => {
        this.successCallBack(response)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
      })
    },
    successCallBack(response) {
      const user = response.data.data
      this.$store.dispatch('auth/updateAuth', {user})
      if (response.data.meta && response.data.meta.redirect === "ForceCreateTeam") {
        const redirectType = response.data.meta.redirect
        this.$store.dispatch('redirect/updateForceRedirect', {redirectType})
        this.$router.replace('team-creation').then(res => {
          this.successMessage(response.data.message)
        })
      } else {
        this.$router.replace('/').then(res => {
          this.successMessage(response.data.message)
        })
      }
    },
    successMessage(message) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: this.$i18n.t('Success'),
          icon: 'EditIcon',
          variant: 'success',
          text: message
        },
      })
    },
  }
}
</script>
